<template>
  <div :class="{ 'tile is-parent': isTiled}">
    <article :class="{ 'tile is-child box detail-page-tile' : isTiled }">
      <p class="title"
        v-if="isTiled">Details</p>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Name</label>
        </div>
        <div class="field-body">
          <div class="field">
            <p class="control">
              <input ref="name"
                class="input is-capitalized"
                maxlength="120"
                type="text"
                v-model="entity.name"
                v-fq-validate="$v.entity.name">
              <span class="help is-danger"
                v-if="!$v.entity.name.required">
                Name is required
              </span>
              <span class="help is-danger"
                v-if="!$v.entity.name.maxLength">
                Maximum 120 characters allowed
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Vendor Acc. No</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <!-- <input class="input" type="number" v-model="entity.vendorAccountNo"> -->
              <the-mask class="input"
                type="text"
                placeholder="##########"
                v-model="entity.vendorAccountNo"
                :mask="vendorMask"
                :tokens="vendorAccountNoTokens" />
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">ABN</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control has-icons-right">
              <!-- <input class="input" ref="abn" type="number" v-model="entity.abn"> -->
              <the-mask v-if="$company.info.countryCode === 'AU'"
                id="abn"
                name="abn"
                ref="abn"
                class="input"
                placeholder="ABN"
                v-model="entity.abn"
                :class="{'is-danger' : $v.entity.abn.$error}"
                :mask="['## ### ### ###']" />
              <input v-else
                v-model="entity.abn"
                id="abn"
                ref="abn"
                class="input is-uppercase"
                maxlength="12">
              <span v-if="!$v.entity.abn.$error && entity.abn && $company.info.countryCode === 'AU'"
                class="icon has-text-success is-right">
                <i class="mdi mdi-check mdi-18px" />
              </span>
              <span class="help is-danger"
                v-if="$v.entity && !$v.entity.abn.minLength">ABN must be at least {{ $v.entity.abn.$params.minLength.min }} letters.</span>
              <span class="help is-danger"
                v-else-if="$v.entity && !$v.entity.abn.validAbn">ABN format is invalid.</span>
              <!-- <span
                class="help is-warning"
                v-if="abnInUse">ABN already exists.</span> -->
              <span class="help is-warning"
                v-if="!entity.abn && $company.info.isWithHold">If you do not enter ABN you will be required<br> to withhold {{ this.$userInfo.withHoldingTax }}% of this supplier's invoices.</span>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">BSB</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <the-mask class="input"
                type="text"
                placeholder="###-###"
                v-model="entity.bsb"
                :mask="['###-###']" />
              <span class="help is-danger"
                v-if="$v.entity && !$v.entity.bsb.minLength">BSB must be at least {{ $v.entity.bsb.$params.minLength.min }} letters.</span>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Acc. No</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <!-- <input class="input" type="number" v-model="entity.accountNo"> -->
              <the-mask class="input"
                type="text"
                placeholder="##########"
                v-model="entity.accountNo"
                :mask="['##########']" />
              <span class="help is-danger"
                v-if="!$v.entity.accountNo.numeric">
                Please enter numbers only
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">Settlement Disc.</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <vue-numeric class="input has-text-right"
                v-model="entity.settlementDiscount"
                v-fq-validate="$v.entity.settlementDiscount"
                @input="setlementKeydown"
                :min="0"
                :max="100"
                symbol="%"
                placeholder="New Markup %"
                symbol-position="suffix"
                :precision="4" />
              <span class="help is-danger"
                v-if="!$v.entity.settlementDiscount.required">Settlement discount is required</span>
              <span class="help is-danger"
                v-if="!$v.entity.settlementDiscount.between">Please enter value between 0 and 100</span>
            </p>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">G/L Account</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="control is-expanded">
              <multiselect v-if="glAccountListCombo"
                v-model="entity.glAccount"
                :options="glAccountListCombo"
                placeholder="Select account"
                label="displayName"
                track-by="accountNo"
                :allow-empty="false">
                <span class="has-text-danger"
                  slot="noResult">G/L account not found.</span>
              </multiselect>
              <span class="help is-danger"
                v-if="!$v.entity.glAccount.required">
                G/L Account is required
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">External Id</label>
        </div>
        <div class="field-body">
          <div class="field is-narrow">
            <p class="control">
              <input ref="externalId"
                class="input is-capitalized"
                maxlength="15"
                type="text"
                v-model="entity.externalId">
            </p>
          </div>
          <div class="field is-narrow">
            <div class="pretty p-icon p-curve p-smooth p-bigger">
              <input type="checkbox"
                v-model="entity.exported"
                :disabled="!$userInfo.isSupportUser">
              <div class="state p-primary">
                <i class="icon mdi mdi-check" />
                <label>Exported</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="$user.hasDelete($route.meta.id)"
        class="field is-horizontal">
        <div class="field-label is-normal" />
        <div class="field-body">
          <div class="field  has-addons">
            <bulma-radio-button type="is-success"
              :native-value="true"
              @click.native="toggleActive(true)"
              v-model="entity.isActive">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span> Active
            </bulma-radio-button>
            <bulma-radio-button type="is-danger"
              :native-value="false"
              v-model="entity.isActive"
              @click.native="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span> In-Active
            </bulma-radio-button>
          </div>
        </div>
      </div>

    </article>
  </div>
</template>
<script>
import VendorService from './VendorService'
// import BulmaSwitch from '@/components/BulmaSwitch'
import { BulmaRadioButton } from '@/components/BulmaRadio'
import { InputValidationDirective } from '@/components/directives'
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'
import VueNumeric from '@/components/VueNumeric'
import _debounce from 'lodash.debounce'

export default {
  inject: ['$vv'],
  components: {
    // BulmaSwitch,
    BulmaRadioButton,
    Multiselect,
    TheMask,
    VueNumeric
  },
  mixins: [InputValidationDirective],
  props: {
    entity: null,
    isTiled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      percentageMask: {
        precision: 4
      },
      glAccountListCombo: null,
      // abnInUse: false,
      vendorMask: Array(10).join('#'),
      vendorAccountNoTokens: {
        '#': {
          pattern: /[0-9a-zA-Z]/,
          transform: (v) => v.toLocaleUpperCase()
        }
      }
    }
  },
  computed: {
    $v() {
      return this.$vv ? this.$vv : this.$v
    }
  },
  watch: {
    // 'entity.settlementDiscount': function(newVal, oldVal) {
    //   if (newVal.indexOf('.') > -1) {
    //     const decimalParts = newVal.split('.')
    //     const decimalPart = decimalParts[1]
    //     if (decimalPart.length > 4) {
    //       this.entity.settlementDiscount = oldVal
    //     }
    //   }
    // },
    // 'entity.abn': function(newVal) {
    //   this.checkAbn()
    // }
  },
  mounted() {
    this.getData()

    this.$v.detailGroup.$touch()

    setTimeout(() => {
      this.$refs.name.focus()
    }, 500)

    // this.checkAbn()
  },
  methods: {
    getData() {
      VendorService.getGlAccountListComboProto().then((result) => {
        this.glAccountListCombo = result
      })
    },
    setlementKeydown(e) {
      // prevent character 'e'
      if (e.keyCode === 69) {
        e.preventDefault()
      }
    },
    toggleActive: _debounce(function (toggle) {
      this.entity.isActive = toggle
      if (this.entity.isActive) {
        // this.checkAbn()
      }
      this.$emit('toggleActive')
    }, 100)
    // checkAbn: _debounce(async function(e) {
    //   this.abnInUse = false

    //   if (this.entity.abn === '' || !this.entity.isActive) {
    //     return
    //   }

    //   this.abnInUse = await VendorService.assertAbnUsed(this.entity.id, this.entity.abn)

    //   if (this.abnInUse) {
    //     this.$notification.openNotificationWithType('warning', 'Saving not allowed', 'ABN already exists')
    //   }
    // }, 500)
  },
  beforeRouteLeave: async function (to, from, next) {
    next()
    // const message = 'Validation errors. Please fix before proceeding'
    // const title = 'Vendor Details'
    // // this.invalids = this.getVuelidationErrors()
    // if (this.$v.detailGroup.$error && !this.entity.isNew) {
    //   this.$router.replace(from.path)
    //   this.$notification.openNotificationWithType('danger', title, message)
    // } else {
    //   next()
    // }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
